import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createStore } from 'redux';
import reducer from './store/reducer';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import Config from './config.json';
import axios from 'axios';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

const store = configureStore({ reducer: reducer });

// Action creator for setting the ref parameter
const setRefParameter = (refValue) => ({
  type: 'SET_URLREF',
  payload: refValue,
});

// Action creator for setting authentication status
const setAuthenticationStatus = (isAuthenticated) => ({
  type: 'SET_AUTHENTICATION',
  payload: { value: isAuthenticated },
});


// Check if the URL contains a "ref" parameter
const urlParams = new URLSearchParams(window.location.search);
const refValue = urlParams.get('ref');

if (refValue) { store.dispatch(setRefParameter(refValue)); }

// Retrieve Token, if it exists
const token = localStorage.getItem('authToken');
if (token) {
  // Set the token as the default header for all axios requests
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  console.log("Restored Auth Token");

  // Dispatch an action to set isAuthenticated to true
  store.dispatch(setAuthenticationStatus(true));

  // Log event 
  axios.put(`${Config.API_BASE_URL}/user/add-detailed-event`, { data: `New Session` }).catch(err => {
    console.log("Error submitting event", err);
  });
} else {
  store.dispatch(setAuthenticationStatus(false));
}

// Log Session
const sessionPayload = { URLRef: refValue || 'N/A' };
axios.post(`${Config.API_BASE_URL}/log/session`, sessionPayload);

// Subscription
store.subscribe(() => {
  if (Config.ENV === 'DEVELOPMENT') {
    console.log('[Subscription]', store.getState());
  } else { store.getState(); }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
