import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NavBar from '../NavBar/NavBar';
import SiteStyles from '../Site.module.css'
import TextStyles from '../TextStyles.module.css'
import ButtonStyles from '../styles/buttons.module.css'
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Footer from '../Footer/Footer';
import Styles from './Home.module.css'
import axios from 'axios';
import Config from '../../config.json'
import { ArrowRightIcon, AudienceIcon } from '../Icons';

const Home = (props) => {
    let navigate = useNavigate();
    const [totalUsers, setTotalUsers] = useState(null);
    const [allTags, setAllTags] = useState([]);
    const [allCourses, setAllCourses] = useState([]);
    const [showAllTags, setShowAllTags] = useState(false);
    const [expandedTag, setExpandedTag] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 850);
    const [isTablet, setIsTablet] = useState(window.innerWidth <= 1000);

    useEffect(() => {
        // Fetch all users
        axios.get(`${Config.API_BASE_URL}/public/totalusers`)
            .then(res => { setTotalUsers(res.data.count.toLocaleString()); })
            .catch(err => { console.log("Error fetching user count") })

        // Fetch all tags
        axios.get(`${Config.API_BASE_URL}/public/tags`, {})
            .then(res => {
                setAllTags(res.data.tags);
                setAllCourses(res.data.allCourses);
            })
            .catch(error => { console.error(error); });
    }, [])

    useEffect(() => {
        // Check mobile size for conditional render 
        const handleResize = () => { setIsMobile(window.innerWidth <= 850); };
        window.addEventListener('resize', handleResize);
        return () => { window.removeEventListener('resize', handleResize); };
    }, []);

    return (
        <div style={{ backgroundColor: 'white' }}>
            <NavBar />

            {/* JUMBOTRON */}
            <div className={Styles.JumbotronBackground} style={{ position: 'relative' }}>
                <Container >
                    <div className={Styles.Jumbotron}>
                        {/* Title */}
                        <br />
                        <p className={TextStyles.Banner} style={{ textTransform: 'none', marginBottom: 4, backgroundColor: 'white', display: 'inline-block', zIndex: 100, }}>From Essays to Interviews</p>
                        <p className={TextStyles.SubBannerHome} style={{ textTransform: 'none', fontWeight: 600, fontSize: '20pt', backgroundColor: 'white', display: 'inline-block', zIndex: 10 }}>The Only Subscription You Need.</p>

                        {/* Pick a tag / course */}
                        <p style={{ marginBottom: 4, marginTop: 12, fontSize: 15, textTransform: 'none' }} id="choose-your-field" className={TextStyles.H6}>{expandedTag ? "WHAT ARE YOU STUDYING FOR?" : "POPULAR"}</p>

                        {/* Preview Fields */}
                        <div style={{ display: 'flex', gap: 4, width: '100%', flexWrap: 'wrap', justifyContent: 'center' }}>

                            {/* Select a tag */}
                            {expandedTag === null && allTags.slice(0, showAllTags ? allTags.length : 3).map(t => <div className={Styles.TagSelectionSlimGrey}
                                onClick={() => { setExpandedTag(t.id); }}>
                                <img className={Styles.TagSelectionSlimImage} src={t.whiteIconURL} />
                                <p className={Styles.PanelSubtitle} style={{ marginBottom: 0, color: 'white', }}>{t.title}</p>
                                <svg className={Styles.Arrow} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"><path d="M5 12h13M12 5l7 7-7 7" /></svg>
                            </div>)}

                            {/* Show Expanded Tag if selected */}
                            {expandedTag &&
                                <>
                                    {allCourses.filter(c => c.tags.includes(expandedTag)).map(c => {
                                        return (
                                            <div id={c.courseId} className={Styles.TagSelectionSlimGrey} style={{ background: `linear-gradient(to right, ${c.mainColorDark}, ${c.mainColorBright})` }}
                                                onClick={() => { navigate('/register', { state: { selectedTag: expandedTag, selectedCourse: c.courseId } }) }}>
                                                <img className={Styles.TagSelectionSlimImage} src={c.iconURL} />
                                                <p className={Styles.PanelSubtitle} style={{ marginBottom: 0, color: 'white', }}>{c.title.replace("Question Bank", "").replace("Essay Bank", "")}</p>
                                                <svg className={Styles.Arrow} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"><path d="M5 12h13M12 5l7 7-7 7" /></svg>
                                            </div>
                                        )
                                    })}
                                    <div id={'other'} className={Styles.TagSelectionSlimGrey}
                                        onClick={() => { navigate('/register', { state: { selectedTag: expandedTag } }) }}>
                                        <p className={Styles.PanelSubtitle} style={{ marginBottom: 0, color: 'white', }}>Other</p>
                                        <svg className={Styles.Arrow} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"><path d="M5 12h13M12 5l7 7-7 7" /></svg>
                                    </div>
                                </>}

                            {/* View all button */}
                            {expandedTag === null && !showAllTags && <div className={Styles.TagSelectionSlimGrey} onClick={() => { setShowAllTags(true) }}>
                                <p className={Styles.PanelSubtitle} style={{ marginBottom: 0, color: 'white' }}>View All</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"><path d="M5 12h13M12 5l7 7-7 7" /></svg>
                            </div>}
                        </div>

                        {
                            /* <div style={{ width: '100%', display: 'flex', justifyContent: isMobile ? 'center' : 'start' }}>
                                <div className={Styles.TagSelectionSlimGrey} style={{
                                    background: 'linear-gradient(45deg, #514A9D, #005AA7)', width: 'min-content',
                                }}>
                                    <a className={TextStyles.Bold} style={{ color: 'white', margin: 0, whiteSpace: 'nowrap', textDecoration: 'none', }} href="/marketplace">
                                        Consultant Marketplace
                                    </a>
                                    <div style={{ display: 'inline-block', marginLeft: 4, }}>
                                        <ArrowRightIcon color="white" width={16} height={16} strokeWidth={2.5} />
                                    </div>
                                </div>
                            </div> */
                        }

                    </div>

                    <div className={Styles.DualPane}>
                        <div className={Styles.Panel}>
                            <p className={TextStyles.Body} style={{ textAlign: 'center', fontWeight: '700', fontSize: '13pt', color: 'black', textTransform: 'uppercase', marginBottom: 6 }}>Banks & Resources</p>
                            <p className={TextStyles.Body} style={{ textAlign: 'center', marginBottom: 4 }}>{isMobile ? "Question banks, essays, and courses" : "Question banks, essay banks, and courses for your important day."}</p>
                            <button className={ButtonStyles.ModernButton} style={{ marginBottom: 12 }} onClick={() => { navigate('/register') }}>Access →</button>
                            <img src={`${Config.IMAGES_BASE_URL}/${isMobile ? 'home_ss_mobile' : 'home_ss'}.jpeg`} style={{ height: isMobile ? 160 : 260, borderRadius: 5, boxShadow: '0px 0px 4px rgba(0,0,0,0.4)', }} />
                        </div>

                        <div className={Styles.Panel}>
                            <p className={TextStyles.Body} style={{ textAlign: 'center', fontWeight: '700', fontSize: '13pt', color: 'black', textTransform: 'uppercase', marginBottom: 6 }}>Consultant Marketplace</p>
                            <p className={TextStyles.Body} style={{ textAlign: 'center', marginBottom: 4 }}>{isMobile ? "Find your admissions mentor" : "Find your mentor to guide you through the admissions process."}</p>
                            <button className={ButtonStyles.ModernButton} style={{ marginBottom: 12 }} onClick={() => { navigate('/marketplace') }}>View →</button>
                            <img src={`${Config.IMAGES_BASE_URL}/experts-2.png`} style={{ height: isMobile ? 160 : 260, borderRadius: 5, }} />
                        </div>
                    </div>


                    <div id="university-gallery">
                        <div style={{ width: '100%', padding: '16px 16px 32px 16px', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                            <div style={{ display: 'flex', color: '#404040' }}>
                                <p className={Styles.trustedText}>Used by students at </p>
                                <small style={{ marginBottom: 8 }}>†</small>
                            </div>
                            <div style={{ display: 'flex', gap: '12px', opacity: 0.8, flexWrap: 'wrap', justifyContent: 'center' }}>
                                <img src={`${Config.IMAGES_BASE_URL}/unis/ucla.jpg`} style={{ height: 34 }} />
                                <img src={`${Config.IMAGES_BASE_URL}/unis/uoft.png`} style={{ height: 34 }} />
                                <img src={`${Config.IMAGES_BASE_URL}/unis/harvard.png`} style={{ height: 34 }} />
                                <img src={`${Config.IMAGES_BASE_URL}/unis/stanford.png`} style={{ height: 34 }} />
                                <img src={`${Config.IMAGES_BASE_URL}/unis/utexas.png`} style={{ height: 34 }} />
                            </div>
                        </div>
                    </div>

                </Container>
            </div >



            <div style={{ width: '100%', backgroundColor: 'rgb(235,235,245)', padding: 24, }}>
                <p className={TextStyles.H6} style={{ textAlign: 'center', marginBottom: 16 }}>NEW UPDATES</p>
                <Container>

                    <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 32, justifyContent: 'center' }}>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: 210, gap: 8 }}>
                            <div style={{
                                display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 8, backgroundColor: 'white', borderRadius: 10,
                                boxShadow: '0px 0px 5px rgba(0,0,0,0.2)',
                            }}>
                                <img src={`${Config.IMAGES_BASE_URL}/ps.jpg`} style={{ width: 200 }} />
                            </div>
                            <p className={TextStyles.Body} style={{ lineHeight: 1.2, marginBottom: 0, }}>This essay bank is a great source of inspiration for completing your medical school application.</p>
                            <button style={{ alignSelf: 'start', marginLeft: -4 }} className={SiteStyles.ModernButton}
                                onClick={() => { navigate('/register') }}>View Essay Bank →</button>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: 210, gap: 8 }}>
                            <div style={{
                                display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 8, backgroundColor: 'white', borderRadius: 10,
                                boxShadow: '0px 0px 5px rgba(0,0,0,0.2)',
                            }}>
                                <img src={`${Config.IMAGES_BASE_URL}/ny.jpg`} style={{ width: 200 }} />
                            </div>
                            <p className={TextStyles.Body} style={{ lineHeight: 1.2, marginBottom: 0, }}>We have added a New York Mini Bank to prepare your health policy knowledge for your applications and interviews at NY schools.</p>
                            <button style={{ alignSelf: 'start', marginLeft: -4 }} className={SiteStyles.ModernButton}
                                onClick={() => { navigate('/register') }}>View All Mini Banks →</button>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: 310, gap: 8 }}>
                            <div style={{
                                display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 8, backgroundColor: 'white', borderRadius: 10,
                                boxShadow: '0px 0px 5px rgba(0,0,0,0.2)',
                            }}>
                                <img src={`${Config.IMAGES_BASE_URL}/video.jpg`} style={{ width: 300 }} />
                            </div>
                            <p className={TextStyles.Body} style={{ lineHeight: 1.2, marginBottom: 0, }}>A beautiful cinematic course that takes you through the med school admissions process (AMCAS).</p>
                            <button style={{ alignSelf: 'start', marginLeft: -4 }} className={SiteStyles.ModernButton}
                                onClick={() => { navigate('/register') }}>View Course →</button>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: 210, gap: 8 }}>
                            <div style={{
                                display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 8, backgroundColor: 'white', borderRadius: 10,
                                boxShadow: '0px 0px 5px rgba(0,0,0,0.2)',
                            }}>
                                <img src={`${Config.IMAGES_BASE_URL}/aamc.jpg`} style={{ width: 200 }} />
                            </div>
                            <p className={TextStyles.Body} style={{ lineHeight: 1.2, marginBottom: 0, }}>Recent updates were made to the PREview™ Question Bank to prepare you for this year's examinations.</p>
                            <button style={{ alignSelf: 'start', marginLeft: -4 }} className={SiteStyles.ModernButton}
                                onClick={() => { navigate('/register') }}>View PREview™ QBank →</button>
                        </div>

                    </div>
                </Container>
            </div>


            {/* BLACK DIV */}
            <div style={{ background: 'black', padding: 24, zIndex: 100 }}>
                <p className={TextStyles.TitleWhite} style={{ textAlign: 'center', marginBottom: 24, }}>Testimonials</p>


                {/* Testimonial gallery */}

                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 16, justifyContent: 'center' }}>

                    {/* TESTIMONIAL 1 */}

                    <div className={Styles.TestimonialCard} style={{ width: '400pxx' }}>
                        <div style={{ display: 'flex', gap: 8, }}>
                            <img src={`${Config.IMAGES_BASE_URL}/courseicons/icon_aamcpreview.png`} className={Styles.TestimonialBullet} style={{ background: 'linear-gradient(45deg, #083b70, #025cb2' }} />
                            <p style={{ marginBottom: 8, fontSize: 'small' }}>PRODUCT: AAMC PREview™ Question Bank</p>
                        </div>
                        <i className={Styles.Quote}>
                            "I scored a 7 on the PREview. The Bright Doctor did help as I did 5 scenarios everyday for about 2 week... Determining the differences between effective and very effective was a main reason I did well."
                        </i>

                        <div style={{ position: 'absolute', textAlign: 'right', right: 16, bottom: 16 }}>
                            <p className={Styles.TestimonialName}>Noah P.</p>
                            <p className={Styles.TestimonialInstitution}>Oregon Institute of Technology | Klamath Falls, OR</p>
                        </div>
                    </div>


                    {/* TESTIMONIAL 2 */}
                    <div className={Styles.TestimonialCard} >
                        <div style={{ display: 'flex', gap: 8, }}>
                            <img src={`${Config.IMAGES_BASE_URL}/courseicons/icon_medinterview.png`} className={Styles.TestimonialBullet} style={{ background: 'linear-gradient(45deg, #16291F, #004C52' }} />
                            <p style={{ marginBottom: 8, fontSize: 'small' }}>PRODUCT: Medicine / MMI Question Bank</p>
                        </div>
                        <i className={Styles.Quote}>
                            "The Bright Doctor was one of few resources I had bookmarked throughout the medical school interview season. Both the MMI and traditional question bank covered just enough ground for me to feel ready going into interviews, but not overwhelmed in the prep process!"
                        </i>

                        <div style={{ position: 'absolute', textAlign: 'right', right: 16, bottom: 16 }}>
                            <p className={Styles.TestimonialName}>Beverly W.</p>
                            <p className={Styles.TestimonialInstitution}>Harvard University | Cambridge, MA</p>
                        </div>
                    </div>

                    {/* TESTIMONIAL 3 */}
                    <div className={Styles.TestimonialCard} >
                        <div style={{ display: 'flex', gap: 8, }}>
                            <img src={`${Config.IMAGES_BASE_URL}/courseicons/icon_casper.png`} className={Styles.TestimonialBullet} style={{ background: 'linear-gradient(45deg, #6e061d, #f42756' }} />
                            <p style={{ marginBottom: 8, fontSize: 'small' }}>PRODUCT: CASPer® Question Bank</p>
                        </div>
                        <i className={Styles.Quote}>
                            "I took the American & Canadian CASPer® this year, and only used this resource to prepare. One of the standout features is the abundance of questions, which challenge your ability to think critically and align yourself with the right mindset for the test. I recommend this resource to anyone looking to prepare for their CASPer® test but do not know where to start."
                        </i>

                        <div style={{ position: 'absolute', textAlign: 'right', right: 16, bottom: 16 }}>
                            <p className={Styles.TestimonialName}>Shayana J.</p>
                            <p className={Styles.TestimonialInstitution}>Queen's University | Kingston, CA</p>
                        </div>
                    </div>

                    {/* TESTIMONIAL 4 */}
                    <div className={Styles.TestimonialCard}>
                        <div style={{ display: 'flex', gap: 8, }}>
                            <img src={`${Config.IMAGES_BASE_URL}/courseicons/icon_casper.png`} className={Styles.TestimonialBullet} style={{ background: 'linear-gradient(45deg, #6e061d, #f42756' }} />
                            <p style={{ marginBottom: 8, fontSize: 'small' }}>PRODUCT: CASPer® Question Bank</p>
                        </div>
                        <i className={Styles.Quote}>
                            "After struggling to reach the 4th quartile following my performance in the 3rd last year, I discovered the BrightDoc QBank. This resource was a game-changer for me. It not only helped me understand expert answers but also emphasized the importance of personalized examples."
                        </i>

                        <div style={{ position: 'absolute', textAlign: 'right', right: 16, bottom: 16 }}>
                            <p className={Styles.TestimonialName}>Jaydev V.</p>
                            <p className={Styles.TestimonialInstitution}>McMaster University | Hamilton, CA</p>
                        </div>
                    </div>

                </div>

                {/* Final CTA */}
                <div style={{ textAlign: 'center', fontFamily: 'Source Sans Pro' }}>
                    <p className={TextStyles.TitleWhite} style={{ textAlign: 'center', margin: '32px 0px 8px 0px', }}>Ready To Excel?</p>
                    <p style={{ marginBottom: 8, color: 'white' }}>All question banks come with 100+ expert questions and answers</p>

                    <button className={SiteStyles.ActionButtonSlim}
                        style={{ backgroundColor: 'white', color: 'black', marginTop: 16, padding: '4px 16px' }} onClick={() => { navigate('/register') }}>Access →</button>
                </div>

                <br />

            </div>

            {/* Footer */}
            <Footer white={true} />
        </div >
    );
}

const mapStateToProps = state => {
    return {
        URLRef: state.URLRef,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setIsAuthenticated: (val) => dispatch({ type: "SET_AUTHENTICATION", payload: { value: val } }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);