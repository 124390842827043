import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NavBar from '../../NavBar/NavBar';
import SiteStyles from '../../Site.module.css'
import TextStyles from '../../TextStyles.module.css'
import DivStyles from '../../styles/divs.module.css'
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Footer from '../../Footer/Footer';
import Styles from './MPHome.module.css'
import axios from 'axios';
import Config from '../../../config.json'

import { ArrowRightIcon, CorporateIcon, MoneyIcon, PersonIcon, SearchIcon } from '../../Icons'
import MPPanel from '../MPPanel/MPPanel';
import MPMarketplace from './MPMarketplace';

const MPHome = (props) => {
    let navigate = useNavigate();

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 850);
    const [isTablet, setIsTablet] = useState(window.innerWidth <= 1000);

    useEffect(() => {
        // Check mobile size for conditional render 
        const handleResize = () => { setIsMobile(window.innerWidth <= 850); };
        window.addEventListener('resize', handleResize);
        return () => { window.removeEventListener('resize', handleResize); };
    }, []);

    return (
        <div style={{ backgroundColor: 'white' }}>
            <NavBar />

            {/* JUMBOTRON */}
            <div className={Styles.JumbotronBackground} style={{ position: 'relative' }}>
                <Container >
                    <div className={Styles.Jumbotron}>

                        <div className={Styles.LeftJumbotronPanel}>

                            <div id="left-pane-content">
                                <br />
                                <br />
                                <p className={Styles.Banner} style={{
                                    textTransform: 'none', marginBottom: 4,
                                    backgroundColor: 'white', display: 'inline-block', zIndex: 100,
                                }}>The Marketplace for Admissions Consultants</p>

                                <div>
                                    <p className={TextStyles.SubBannerHome} style={{ textTransform: 'none', fontWeight: 600, fontSize: '20pt', backgroundColor: 'white', display: 'inline-block', zIndex: 10 }}>Access Expertise Without Breaking the Bank.</p>
                                </div>

                                <div style={{ display: 'flex', width: '100%', justifyContent: isMobile ? 'center' : null, marginBottom: 16 }}>
                                    <div className={Styles.TagSelectionSlimGrey} style={{ width: 'min-content', marginTop: 8 }}
                                        onClick={() => { document.getElementById('marketplace-grid')?.scrollIntoView({ behavior: 'smooth' }); }}>
                                        <div style={{ minWidth: 28, marginBottom: 4 }}>
                                            <SearchIcon color="white" />
                                        </div>
                                        <p className={Styles.PanelSubtitle} style={{
                                            marginBottom: 0, color: 'white',
                                            whiteSpace: 'nowrap', // Prevents text from wrapping
                                            overflow: 'hidden', // Keeps the text within the container
                                            textOverflow: 'ellipsis' // Adds an ellipsis if the text overflows
                                        }}>Find Your Consultant</p>
                                    </div>
                                </div>
                            </div>

                            {/* <div id="university-gallery">
                                <div style={{ width: '100%', padding: '16px 16px 32px 16px', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                    <div style={{ display: 'flex', color: '#404040' }}>
                                        <p className={Styles.trustedText}>Used by students at </p>
                                        <small style={{ marginBottom: 8 }}>†</small>
                                    </div>
                                    <div style={{ display: 'flex', gap: '12px', opacity: 0.8, flexWrap: 'wrap', justifyContent: 'center' }}>
                                        <img src={`${Config.IMAGES_BASE_URL}/unis/ucla.jpg`} style={{ height: 34 }} />
                                        <img src={`${Config.IMAGES_BASE_URL}/unis/uoft.png`} style={{ height: 34 }} />
                                        <img src={`${Config.IMAGES_BASE_URL}/unis/harvard.png`} style={{ height: 34 }} />
                                        <img src={`${Config.IMAGES_BASE_URL}/unis/stanford.png`} style={{ height: 34 }} />
                                        <img src={`${Config.IMAGES_BASE_URL}/unis/utexas.png`} style={{ height: 34 }} />
                                    </div>
                                </div>
                            </div> */}

                        </div>

                        <div className={Styles.RightJumbotronPanel}>
                            <img src={`${Config.IMAGES_BASE_URL}/experts-2.png`} className={Styles.MainImage} />
                        </div>

                    </div>
                </Container>
            </div >


            <div style={{ width: '100%', backgroundColor: 'black', padding: '16px' }}>
                {/* <p className={TextStyles.H6} style={{ textAlign: 'center', marginBottom: 16, color: 'white', }}>OUR APPROACH</p> */}
                <Container>
                    <div style={{ width: '100%', justifyContent: 'space-between', gap: 16, display: 'flex', flexWrap: 'wrap' }}>

                        {/* Left panel */}
                        <div style={{ width: isMobile ? '100%' : '45%', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 12, scale: isMobile ? '80%' : '100%' }}>

                                <div className={Styles.ApproachTitle}>
                                    <PersonIcon color="white" width={32} height={32} />
                                    <p className={TextStyles.BodyWhite} style={{ fontSize: '11pt', fontWeight: 600, margin: 0, }}>APPLICANT (YOU)</p>
                                </div>

                                <ArrowRightIcon color={"white"} />

                                <div className={Styles.ApproachTitle} style={{ width: 180 }}>
                                    <PersonIcon color="white" width={32} height={32} />
                                    <p className={TextStyles.BodyWhite} style={{ fontSize: '11pt', fontWeight: 600, margin: 0, }}>RELEVANT CONSULTANT</p>
                                </div>
                            </div>

                            <p className={TextStyles.BodyWhite} style={{ fontSize: '11pt', fontWeight: 500, margin: 0, textAlign: 'center', }}>Our marketplace connects you directly with the consultant that best fits your needs.</p>

                        </div>

                        {/* Right panel */}
                        <div style={{ width: isMobile ? '100%' : '45%', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>

                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 12, scale: isMobile ? '70%' : '100%', }}>

                                <div className={Styles.ApproachTitle} style={{ width: 120 }}>
                                    <PersonIcon color="white" width={32} height={32} />
                                    <p className={TextStyles.BodyWhite} style={{ fontSize: '11pt', fontWeight: 600, margin: 0, }}>APPLICANT</p>
                                </div>

                                <ArrowRightIcon color={"white"} />

                                <div className={Styles.ApproachTitle} style={{ width: 120 }}>
                                    <CorporateIcon color={"white"} width={36} height={36} />
                                    <p className={TextStyles.BodyWhite} style={{ fontSize: '11pt', fontWeight: 600, margin: 0, }}>COMPANY ($$$)</p>
                                </div>
                                <ArrowRightIcon color={"white"} />

                                <div className={Styles.ApproachTitle}>
                                    <PersonIcon color="white" width={32} height={32} />
                                    <p className={TextStyles.BodyWhite} style={{ fontSize: '11pt', fontWeight: 600, margin: 0, }}>RANDOM CONSULTANT</p>
                                </div>
                            </div>

                            <p className={TextStyles.BodyWhite} style={{ fontSize: '11pt', fontWeight: 500, margin: 0, textAlign: 'center', }}>Traditional consulting companies eat up to 80% of your money as commission.</p>

                        </div>

                    </div>
                </Container>
            </div>

            <MPMarketplace />

            {/* BLACK DIV */}
            <div style={{ background: 'black', padding: 24, zIndex: 100 }}>
                {/* Final CTA */}
                <div style={{ textAlign: 'center', fontFamily: 'Source Sans Pro' }}>
                    <p className={TextStyles.TitleWhite} style={{ textAlign: 'center', margin: '32px 0px 8px 0px', }}>Ready To Excel?</p>
                    {<p style={{ marginBottom: 8, color: 'white' }}>All question banks come with 100+ expert questions and answers</p>}

                    <button className={SiteStyles.ActionButtonSlim}
                        style={{ backgroundColor: 'white', color: 'black', marginTop: 16, padding: '4px 16px' }} onClick={() => { navigate('/register') }}>Access →</button>
                </div>

                <br />

            </div>

            {/* Footer */}
            <Footer white={true} />
        </div >
    );
}

const mapStateToProps = state => {
    return {
        URLRef: state.URLRef,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setIsAuthenticated: (val) => dispatch({ type: "SET_AUTHENTICATION", payload: { value: val } }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MPHome);