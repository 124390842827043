import React, { useState } from 'react';

const StarRating = ({ rating, onRatingChange }) => {
    const stars = Array(5).fill(0);
    const [hoveredIndex, setHoveredIndex] = useState(-1);

    // Round up the rating
    const roundedRating = Math.ceil(rating);

    return (
        <div style={{ display: 'flex', gap: '8px' }} onMouseLeave={() => setHoveredIndex(-1)}>
            {stars.map((_, index) => {
                const myIndex = index + 1;
                let fillColor;
                if (myIndex <= roundedRating || myIndex <= hoveredIndex) {
                    fillColor = myIndex <= roundedRating ? '#ffc107' : '#ffde7d'; // Apply yellow for rating and hover color
                } else {
                    fillColor = '#e4e5e9'; // Default color
                }

                return (
                    <button
                        type="button"
                        key={myIndex}
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            padding: 0,
                            margin: 0,
                            width: '24px',
                            height: '24px',
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        onClick={() => onRatingChange(myIndex)}
                        onMouseOver={() => setHoveredIndex(myIndex)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill={fillColor}
                            viewBox="0 0 24 24" // Adjusted viewBox
                            width="24"
                            height="24"
                            strokeLinejoin='round'
                            style={{ display: 'block' }} // Ensure SVG fills the button
                        >
                            <path
                                d="M12 2l3.09 6.26L22 9.27l-5 4.87L18.18 22 12 18.54 5.82 22 7 14.14 2 9.27l6.91-1.01L12 2z"
                                stroke="#f0932b"
                                strokeWidth="1"
                            />
                            <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                    </button>
                );
            })}
        </div>
    );
};

export default StarRating;
