import { render } from '@testing-library/react';
import React, { Component } from 'react';
import Styles from '../../App/WebApp.module.css'

const SuccessItem = (text) => {
    return (
        <div>
            <div style={{ margin: '12px 0px 12px 0px' }} />
            <span style={{
                backgroundColor: '#136352', padding: '4px 12px 4px 12px',
                color: 'white', fontWeight: 'bold', fontFamily: 'Source Sans pro',
                borderRadius: '5px 0px 0px 5px'
            }}>Success</span>
            <span style={{
                backgroundColor: '#e0fff7', color: '#136352', padding: '4px 8px 4px 8px', fontFamily: 'Source Sans Pro',
                borderRadius: '0px 5px 5px 0px'
            }}>{text}</span>
        </div >
    )
}

export default SuccessItem;