import React, { useState, useEffect } from 'react';
import { Form, ListGroup, Button, Container } from 'react-bootstrap';
import axios from 'axios';
import { connect } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom';
import Config from '../../config.json'
import SiteStyles from '../Site.module.css'
import ButtonStyles from '../styles/buttons.module.css'
import { TextInput, TextAreaInput, DualSegmentedControlInput, MultipleSegmentedControlInput } from './Subcomponents/text-inputs';

const AddQEssay = (props) => {
    let navigate = useNavigate();
    let location = useLocation();

    const questionType = "essay";
    const [courseId, setCourseId] = useState("");
    const [questionId, setQuestionId] = useState("");

    const [categoryOptions, setCategoryOptions] = useState([]);

    // Common to all questions
    const [isFree, setIsFree] = useState(false);
    const [freePriorityNumber, setFreePriorityNumber] = useState(0);
    const [category, setCategory] = useState("");
    const [comments, setComments] = useState("");
    const [reviews, setReviews] = useState("");
    const [reviewAvg, setReviewAvg] = useState("");
    const [multimediaType, setMultimediaType] = useState("none");
    const [multimediaLink, setMultimediaLink] = useState("");


    // Specific to essays
    const [question, setQuestion] = useState("");
    const [essayContent, setEssayContent] = useState("");
    const [essayTitle, setEssayTitle] = useState("");

    const [allSchools, setAllSchools] = useState([]);
    const [schools, setSchools] = useState([]);


    const [isEditing, setIsEditing] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");



    useEffect(() => {
        if (location.state && location.state.courseId) {
            setCourseId(location.state.courseId);

            const fetchSchools = async () => {
                try {
                    const response = await axios.get(`${Config.API_BASE_URL}/school/school-list`);
                    const schools = response.data;
                    setAllSchools(schools);
                } catch (err) { console.log(err); }
            }
            fetchSchools();

            // Fetch the possible question categories
            axios.get(`${Config.API_BASE_URL}/course/${location.state.courseId}/categories`).then(res => {
                setCategoryOptions(res.data.data);
                setError("");
            }).catch(err => {
                setError("Error in retrieving course");
                console.log(err);
            });

            // Check if editing an existing question
            if (location.state.questionId) {
                setIsEditing(true);
                setQuestionId(location.state.questionId)
                // Fetch the question   

                const fetchQuestion = async () => {
                    try {
                        const response = await axios.get(`${Config.API_BASE_URL}/question/${location.state.courseId}/${location.state.questionId}`);

                        const questionData = response.data.data;

                        setIsFree(questionData.isFree);
                        setFreePriorityNumber(questionData.freePriorityNumber);
                        setCategory(questionData.category);
                        setComments(questionData.comments);
                        setReviews(questionData.reviews);
                        setReviewAvg(questionData.reviewAvg);
                        setMultimediaType(questionData.multimediaType);
                        setMultimediaLink(questionData.multimediaLink);
                        setSchools(questionData.schools ?? []);

                        setQuestion(questionData.question);
                        setEssayContent(questionData.essayContent);
                        setEssayTitle(questionData.essayTitle);
                    } catch (err) { console.log(err); }
                };
                fetchQuestion();



            }
        }
    }, [location.state]);

    const submitQuestion = async () => {
        try {
            // Create the question data object
            const questionData = {
                type: questionType,
                isFree,
                freePriorityNumber,
                category,
                schools,
                comments: [],
                reviews: [],
                reviewAverage: 0.0,
                multimediaType,
                multimediaLink: multimediaLink.trim(),
                question: question.trim(),
                essayContent: essayContent.trim(),
                essayTitle: essayTitle.trim(),
            };

            // Send the question data object to the server
            const response = await axios.post(`${Config.API_BASE_URL}/question/${courseId}/create`, { questionData });

            // Handle the server response
            if (response.data.success) {
                setSuccess(true);
                setError('');
                navigate(-1); // Redirect back to the previous page
            } else {
                setError(response.data.message);
                setSuccess(false);
            }
        } catch (err) {
            console.error(err);
            setError('An error occurred while submitting the question.');
            setSuccess(false);
        }
    };

    const updateQuestion = async () => {
        try {

            // Create the question data object
            const questionData = {
                isFree,
                freePriorityNumber,
                category,
                schools,
                multimediaType,
                multimediaLink: multimediaLink.trim(),
                question: question.trim(),
                essayContent: essayContent.trim(),
                essayTitle: essayTitle.trim(),
            };


            // Send the updated question data object to the server
            const response = await axios.put(
                `${Config.API_BASE_URL}/question/${courseId}/${questionId}/update`,
                { questionData: questionData }
            );

            // Handle the server response
            if (response.data.success) {
                setSuccess(true);
                setError("");
                // Go back
                navigate(-1); // Redirect back to the previous page
            } else {
                setError(response.data.message);
                setSuccess(false);
            }
        } catch (err) {
            console.error(err);
            setError("An error occurred while updating the question.");
            setSuccess(false);
        }
    };

    // Helper function to normalize text for search
    const normalizeText = text => text.toLowerCase().replace(/[\W_]+/g, "");

    const SchoolSelector = ({ allSchools, setSchools, schools }) => {
        const [searchTerm, setSearchTerm] = useState("");

        const handleSearchChange = event => {
            setSearchTerm(event.target.value);
        };

        const handleAddSchool = school => {
            if (!schools.some(s => s.schoolId === school.schoolId)) {
                setSchools([...schools, school]);
            }
        };

        const handleRemoveSchool = schoolId => {
            setSchools(schools.filter(s => s.schoolId !== schoolId));
        };

        const filteredSchools = allSchools
            .filter(school => normalizeText(school.title).includes(normalizeText(searchTerm)))
            .slice(0, 5);

        return (
            <div>
                <Form.Control
                    type="text"
                    placeholder="Search for schools"
                    onChange={handleSearchChange}
                    value={searchTerm}
                />
                <ListGroup>
                    {filteredSchools.map(school => (
                        <ListGroup.Item key={school.schoolId} action onClick={() => handleAddSchool(school)}>
                            {school.title}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
                <div style={{ display: 'flex', gap: 8 }}>
                    {schools.map(school => (
                        <div key={school.schoolId} style={{ backgroundColor: 'rgb(225,225,235)', borderRadius: 30, padding: '4px 16px' }}>
                            {school.title} <button className={ButtonStyles.ModernButton} style={{ color: 'red' }} onClick={() => handleRemoveSchool(school.schoolId)}>Remove</button>
                        </div>
                    ))}
                </div>
            </div>
        );
    };


    // JSX

    return (
        <Container>
            <h1>{isEditing ? "Edit Essay" : "Add Essay"}</h1>
            <p>{courseId ? `Course ID: ${courseId}` : "No Course ID provided"}</p>
            <hr />

            <SchoolSelector allSchools={allSchools} setSchools={setSchools} schools={schools} />

            <hr />

            <div className={SiteStyles.DynamicPane}>
                <DualSegmentedControlInput label="Free Essay" choiceA="Yes" choiceB="No" value={isFree} onChange={setIsFree} />
            </div>


            <MultipleSegmentedControlInput label="Essay Category" value={category} onChange={setCategory} options={categoryOptions.map(x => x.id)} />

            <Form>
                <TextInput label="Essay Title (Optional) — Usually relevant to personal statements" value={essayTitle} onChange={setEssayTitle} width="100%" />

                <TextInput label="Essay Prompt (Optional) - Usually relevant to secondary essay questions and not personal statements" value={question} onChange={setQuestion} width="100%" />

                <TextAreaInput label="Essay" value={essayContent} onChange={setEssayContent} height="250px" />

                <hr />

                <Form.Group controlId="multimediaType">
                    <Form.Label>Multimedia Type</Form.Label>
                    <Form.Control
                        as="select"
                        value={multimediaType}
                        onChange={(event) => setMultimediaType(event.target.value)}
                    >
                        <option value="none">None</option>
                        <option value="image">Image</option>
                        <option value="video">Video</option>
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="multimediaLink">
                    <Form.Label>Multimedia Link</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter multimedia link"
                        value={multimediaLink}
                        onChange={(event) => setMultimediaLink(event.target.value)}
                        readOnly={multimediaType === "none"}
                    />
                </Form.Group>

                <hr />

                {/* Add the rest of the form fields following the reference code provided */}

                <Button variant="primary" onClick={() => {
                    if (isEditing) { updateQuestion(); }
                    else { submitQuestion(); }
                }}> {isEditing ? "Update" : "Add"}</Button>
                <Button variant="secondary" onClick={() => { navigate(-1); }}>Cancel</Button>

                {success && (
                    <p style={{ color: "green" }}>Successfully submitted question</p>
                )}
                {error && <p style={{ color: "red" }}>{error}</p>}
            </Form>
            <br />
        </Container >
    );
}

const mapStateToProps = state => { return {} };
const mapDispatchToProps = (dispatch, ownProps) => { return {} }

export default connect(mapStateToProps, mapDispatchToProps)(AddQEssay);