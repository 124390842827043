import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import NavBar from '../../NavBar/NavBar';
import SiteStyles from '../../Site.module.css'
import TextStyles from '../../styles/text.module.css'
import ButtonStyles from '../../styles/buttons.module.css'
import DivStyles from '../../styles/divs.module.css'
import { Container } from 'react-bootstrap';
import Footer from '../../Footer/Footer';
import Styles from './MPConsultant.module.css'
import axios from 'axios';
import Config from '../../../config.json'

import { ArrowRightIcon, CorporateIcon, MoneyIcon, PersonIcon, SearchIcon } from '../../Icons'
import MPPanel from '../MPPanel/MPPanel';
import MPConsultantDetailedView from './MPConsultantDetailedView';

const MPConsultant = (props) => {
    let navigate = useNavigate();
    let location = useLocation();

    const handleBackClick = () => {
        if (location && location.state && location.state.goBack) {
            navigate(-1)
        } else {
            navigate('/marketplace')
        }
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 850);
    const [isTablet, setIsTablet] = useState(window.innerWidth <= 1000);

    useEffect(() => {

        // Check mobile size for conditional render 
        const handleResize = () => { setIsMobile(window.innerWidth <= 850); };
        window.addEventListener('resize', handleResize);
        return () => { window.removeEventListener('resize', handleResize); };
    }, []);

    return (
        <div style={{ backgroundColor: 'rgb(235,235,245)', }}>
            <NavBar />

            <Container>
                <div style={{ padding: 32 }}>
                    <button
                        style={{ marginBottom: 16 }}
                        className={ButtonStyles.StandardDark}
                        onClick={handleBackClick}
                    >
                        Back
                    </button>
                    <MPConsultantDetailedView />
                </div>
            </Container>

            {/* Footer */}
            <Footer white={true} />
        </div >
    );
}

const mapStateToProps = state => {
    return {
        URLRef: state.URLRef,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setIsAuthenticated: (val) => dispatch({ type: "SET_AUTHENTICATION", payload: { value: val } }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MPConsultant);