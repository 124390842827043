// FavoriteSchools.js
import React, { useState, useEffect } from 'react';
import NavBar from '../NavBar/NavBar';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import SiteStyles from '../Site.module.css'
import TextStyles from '../TextStyles.module.css'
import Config from '../../config.json';
import School from './School';
import './slider.css'
import { connect } from 'react-redux';

const FavoriteSchools = ({ isAuthenticated }) => {
    let navigate = useNavigate();

    const [schools, setSchools] = useState([]);
    const [favorites, setFavorites] = useState([]);

    const refreshFavorites = () => {
        if (isAuthenticated) {
            axios.post(`${Config.API_BASE_URL}/user/get-favorite-schools`, {})
                .then(res => { setFavorites(res.data.favoriteSchools); })
                .catch(error => { console.error(error); });
        }
    }

    const fetchSchoolList = () => {
        axios.get(`${Config.API_BASE_URL}/school/school-list`, {})
            .then(res => { setSchools(res.data); })
            .catch(error => { console.error(error); });
    }

    // OTHER 
    useEffect(() => { fetchSchoolList(); }, []);

    useEffect(() => { refreshFavorites(); }, [isAuthenticated]);

    return (
        <div>
            <NavBar />
            <Container>
                <div style={{
                    border: '1px solid rgb(240,240,250)',
                    fontFamily: 'Source Sans pro',
                    backgroundColor: 'rgb(245,245,255)',
                    padding: 16, borderRadius: 5,
                }}>
                    <button className={SiteStyles.ModernButton}
                        onClick={() => { navigate('/mstar') }}>← Back</button>
                    <p class="title-tbd">{`My Favorite Schools (${favorites.length})`}</p>
                    {favorites.length > 0 ? (
                        schools.filter(x => favorites.includes(x.schoolId)).map(school => (
                            <School key={school.schoolId} school={school} authenticated={isAuthenticated} triggerRefresh={refreshFavorites} isFavorite={true} />
                        ))
                    ) : (
                        <div>
                            <p>You have not added any schools to your favorites.</p>
                            <button className={SiteStyles.ActionButtonSlim} onClick={() => navigate('/mstar')}>Find Schools</button>
                        </div>
                    )}
                </div>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.isAuthenticated,
    };
};

export default connect(mapStateToProps)(FavoriteSchools);
