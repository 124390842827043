function processPolicy(policy) {
    // Check for any other HTML tags
    const unwantedTags = policy.match(/<\/?(?!b\b|u\b|i\b)\w+>/g);
    if (unwantedTags) {
        console.log("Detected unsupported or potentially malicious HTML tags.");
        return; // You can return the original policy or throw an error or do any other action
    }

    // Convert lines starting with * into list items
    policy = policy.replace(/^\s*\*(.+)/gm, '<li style="margin-bottom: 5px;">$1</li>');

    // Wrap consecutive list items within <ul></ul> with reduced margins
    policy = policy.replace(/(<li style="margin-bottom: 5px;">.*?<\/li>)(\n(?=<li style="margin-bottom: 5px;">)|(?!\n<li style="margin-bottom: 5px;">))/g, '<ul style="margin-bottom: 5px;">$1</ul>');

    return policy.replace(/<b>(.*?)<\/b>/g, '<strong>$1</strong>')
        .replace(/<i>(.*?)<\/i>/g, '<em>$1</em>')
        .replace(/<u>(.*?)<\/u>/g, '<span style="text-decoration:underline">$1</span>')
        .replace(/\n/g, '<br/>');
}

export default processPolicy;
