import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import NavBar from '../NavBar/NavBar';
import SiteStyles from '../Site.module.css'
import TextStyles from '../TextStyles.module.css'
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import SubscriptionCard from '../Elements/SubscriptionCard';
// import Styles from './Home.module.css'
import Config from '../../config.json'
import axios from 'axios';
import Styles from './SchoolDirectory.module.css'

function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


function School({ school, authenticated = false, triggerRefresh, isFavorite }) {
    let navigate = useNavigate()
    console.log(school)
    return (
        <div className={Styles.Card} style={{ overflow: 'hidden', borderRadius: 3 }}>

            <div onClick={() => { window.location = `${Config.STATIC_BASE_URL}/school/${school.schoolId}` }}>

                {/* <div style={{
                    display: 'flex', gap: 8, alignItems: 'center', paddingBottom: 8, borderBottom: '1px solid rgb(215,215,225)',
                    marginBottom: 8, justifyContent: 'space-between', flexWrap: 'wrap',
                }}>
                    <div style={{ display: 'flex', gap: 6, alignItems: 'center', }}>

                       

                        <div style={{
                            height: 20, width: 30, minWidth: 30, minHeight: 20,
                            border: '2px solid black', borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center',
                        }}>
                            <span style={{ fontWeight: 'bold', fontSize: '10pt', }}>{school.MDDO}</span>
                        </div>
                    </div>
                    <p style={{
                        margin: 0, fontWeight: '600', backgroundColor: school.color, color: 'white',
                        padding: '0px 12px', borderRadius: 5,
                    }}>{school.schoolLocationCity}, {school.schoolLocationState}</p>
                </div> */}

                <div style={{
                    display: 'flex', gap: 16, marginBottom: 4, flexWrap: 'wrap',
                }}>
                    <a href={`${Config.STATIC_BASE_URL}/school/${school.schoolId}`}
                        className={Styles.SchoolLink}
                        style={{ textDecoration: 'none', width: 250, fontSize: 15, fontWeight: 600, lineHeight: '1.3em' }}>
                        {school.title}
                    </a>

                    <div>

                        {/* GPA */}
                        <div style={{ display: 'flex' }}>
                            <p style={{ color: 'black', fontSize: 14, lineHeight: '1.3em', fontWeight: '600', margin: 0, width: 50 }}>GPA: </p>
                            <p style={{ color: 'black', fontSize: 14, lineHeight: '1.3em', margin: 0, width: 60 }}>{school.avgGPA}</p>
                        </div>

                        {/* MCAT */}
                        <div style={{ display: 'flex' }}>
                            <p style={{ color: 'black', fontSize: 14, lineHeight: '1.3em', fontWeight: '600', margin: 0, width: 50 }}>MCAT: </p>
                            <p style={{ color: 'black', fontSize: 14, lineHeight: '1.3em', margin: 0, width: 60 }}>{school.avgMCAT === null && school.schoolLocationCountry === 'Canada' ? "No MCAT" : school.avgMCAT}</p>
                        </div>
                    </div>

                    <div>
                        {/* Tuition IS */}
                        <div style={{ display: 'flex' }}>
                            <p style={{ color: 'black', fontSize: 14, lineHeight: '1.3em', fontWeight: '600', margin: 0, width: 96 }}>Tuition IS: </p>
                            <p style={{ color: 'black', fontSize: 14, lineHeight: '1.3em', margin: 0, width: 70 }}>${formatNumberWithCommas(school.tuitionIS)}</p>
                        </div>

                        {/* Tuition OOS */}
                        <div style={{ display: 'flex' }}>
                            <p style={{ color: 'black', fontSize: 14, lineHeight: '1.3em', fontWeight: '600', margin: 0, width: 96 }}>Tuition OOS: </p>
                            <p style={{ color: 'black', fontSize: 14, lineHeight: '1.3em', margin: 0, width: 70 }}>${formatNumberWithCommas(school.tuitionOOS)}</p>
                        </div>
                    </div>

                    <div>
                        {/* Class Size */}
                        <div style={{ display: 'flex' }}>
                            <p style={{ color: 'black', fontSize: 14, lineHeight: '1.3em', fontWeight: '600', margin: 0, width: 130 }}>Class Size: </p>
                            <p style={{ color: 'black', fontSize: 14, lineHeight: '1.3em', margin: 0, width: 40 }}>{school.mdAcceptances}</p>
                        </div>

                        {/* Competitiveness */}
                        <div style={{ display: 'flex' }}>
                            <p style={{ color: 'black', fontSize: 14, lineHeight: '1.3em', fontWeight: '600', margin: 0, width: 130 }}>Competitiveness: </p>
                            <p style={{ color: 'black', fontSize: 14, lineHeight: '1.3em', margin: 0, width: 80 }}>{school.competitiveness.replace('Competitive', '').replace('ly', '')}</p>
                        </div>
                    </div>

                    <div>
                        {/* Class Size */}
                        <div style={{ display: 'flex' }}>
                            <p style={{ color: 'black', fontWeight: '600', fontSize: 14, lineHeight: '1.3em', margin: 0, width: 60 }}>Accepts: </p>
                            <p style={{ color: 'black', margin: 0, width: 100, fontSize: 14, lineHeight: '1.3em' }}>{`${school.acceptsOOS ? "OOS" : ""} ${school.acceptsCanadian ? "CAN" : ""} ${school.acceptsInternational ? "INTL" : ""}`}</p>
                        </div>

                        <div style={{ display: 'flex', gap: 8, marginTop: 3 }}>
                            {school.requiresCASPer &&
                                <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                                    <p style={{ margin: 0, fontWeight: '600', textTransform: 'uppercase', fontSize: 13 }}>Requires CASPer®</p>
                                </div>
                            }
                            {school.requiresAAMCPREview &&
                                <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                                    <p style={{ margin: 0, fontWeight: '600', textTransform: 'uppercase', fontSize: 13 }}>Requires AAMC PREview™</p>
                                </div>
                            }
                        </div>
                    </div>

                </div>

            </div>

            <div style={{
                display: 'flex', gap: 8, flexWrap: 'wrap', justifyContent: 'space-between', backgroundColor: 'rgb(240,240,250)',
                marginLeft: -12, width: 'calc(100% + 24px)', marginBottom: -12, padding: '0px 12px',
            }}>
                <p style={{
                    margin: 0, fontWeight: '600', color: 'black',
                }}>{school.schoolLocationCity}, {school.schoolLocationState}</p>


                <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap', }}>
                    <div className='cool-buttons'
                        onClick={() => { window.location = `${Config.STATIC_BASE_URL}/school/${school.schoolId}` }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                        <p style={{ margin: 0, fontWeight: '600', }}>View</p>
                    </div>
                    <div className='cool-buttons'
                        onClick={() => {
                            if (authenticated) {
                                if (isFavorite) {
                                    // Remove Favorite
                                    axios.post(`${Config.API_BASE_URL}/user/remove-favorite-school`, { schoolId: school.schoolId })
                                        .then(res => { triggerRefresh(); })
                                        .catch(error => { console.error(error); });
                                } else {
                                    // Add Favorite
                                    axios.post(`${Config.API_BASE_URL}/user/add-favorite-school`, { schoolId: school.schoolId })
                                        .then(res => { triggerRefresh(); })
                                        .catch(error => { console.error(error); });
                                }
                            } else {
                                navigate('/register')
                            }
                        }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill={isFavorite ? 'black' : 'none'} stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                        <p style={{ margin: 0, fontWeight: '600', }}>{authenticated ? (isFavorite ? "Favorite" : "Favorite") : "Sign Up to Add Favorite"}</p>
                    </div>
                </div>
            </div>


        </div >
    );
}

export default School;
