import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Styles from './Contact.module.css';
import SiteStyles from '../Site.module.css'
import { useNavigate } from 'react-router-dom';
import TextStyles from '../TextStyles.module.css'
import Config from '../../config.json'
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import { Container } from 'react-bootstrap';


const Contact = () => {
    let navigate = useNavigate();


    return (
        <div>
            <NavBar />
            <br />

            {/* Body */}
            <Container>
                <h1 className={TextStyles.Title}>The Bright Doctor</h1>
                <p className={TextStyles.Body} style={{ color: 'black' }}>Toronto, Canada</p>

                <br />
                <p className={TextStyles.Body}>Let's talk! There are many ways to reach us.</p>


                <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>

                    <div className={Styles.ContactPane}>
                        <b className={TextStyles.Body}>General Support</b>
                        <div>
                            {/* Anti spam mechanism - spans within divs */}
                            <div style={{ display: 'inline-block' }}>
                                <span className={TextStyles.Body}>info</span>
                            </div>
                            <div style={{ display: 'inline-block' }}>
                                <span className={TextStyles.Body}>@</span>
                            </div>
                            <div style={{ display: 'inline-block' }}>
                                <span className={TextStyles.Body}>brightstudent</span>
                            </div>
                            <div style={{ display: 'inline-block' }}>
                                <span className={TextStyles.Body}>.</span>
                            </div>
                            <div style={{ display: 'inline-block' }}>
                                <span className={TextStyles.Body}>org</span>
                            </div>
                        </div>
                    </div>

                    <div className={Styles.ContactPane}>
                        <b className={TextStyles.Body}>Business Inquiries</b>
                        <div>
                            {/* Anti spam mechanism - spans within divs */}
                            <div style={{ display: 'inline-block' }}>
                                <span className={TextStyles.Body}>brightstudentllc</span>
                            </div>
                            <div style={{ display: 'inline-block' }}>
                                <span className={TextStyles.Body}>@</span>
                            </div>
                            <div style={{ display: 'inline-block' }}>
                                <span className={TextStyles.Body}>gmail</span>
                            </div>
                            <div style={{ display: 'inline-block' }}>
                                <span className={TextStyles.Body}>.</span>
                            </div>
                            <div style={{ display: 'inline-block' }}>
                                <span className={TextStyles.Body}>com</span>
                            </div>
                        </div>
                    </div>

                    <div className={Styles.ContactPane}>
                        <b className={TextStyles.Body}>Instagram</b>
                        <div>
                            <span className={TextStyles.Body}>@thebrightdoc</span>
                        </div>
                    </div>
                </div>

                <p className={TextStyles.Body}></p>
            </Container>

            <br />
            <Footer />
        </div>
    );
}

export default Contact;
