import React, { useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import axios from 'axios';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import SuccessItem from '../App/subcomponents/SuccessItem';
import Config from '../../config.json'
import formatUnixTime from '../App/helpers/formatUnixTime'

import SiteStyles from '../Site.module.css'
import TextStyles from '../TextStyles.module.css'
import ErrorItem from '../App/subcomponents/ErrorItem';

import { DualSegmentedControlInput, TextInput } from './Subcomponents/text-inputs'


const AnswerData = (props) => {
    let navigate = useNavigate();

    const [isAdminUser, setIsAdminUser] = useState(false);
    const [questionId, setQuestionId] = useState("");
    const [answers, setAnswers] = useState([]);
    const [hideEmpty, setHideEmpty] = useState(false);
    const [hideVideo, setHideVideo] = useState(false);

    useEffect(() => {
        axios.get(`${Config.API_BASE_URL}/auth/check-admin`)
            .then(res => {
                console.log(`isAdmin: ${res.data.isAdmin === true}`)
                if (res.data.isAdmin === true) {
                    setIsAdminUser(true);
                }
            })
            .catch(error => { console.error(error); });
    }, [])

    const fetchQuestion = () => {
        var qId = questionId.trim();
        setAnswers([]);

        axios.get(`${Config.API_BASE_URL}/question/answers/${qId}`)
            .then(res => {
                setAnswers(res.data.answers);
            })
            .catch(error => { console.error(error); });
    }

    const fetchAnalysis = () => {
        axios.post(`${Config.API_BASE_URL}/user/getuserdemographics`, { userIds: answers.map(x => x.user) })
            .then(res => {
                console.log(res.data);
            })
            .catch(error => { console.error(error); });
    }

    const displayAnswers = (ad) => {
        if (hideEmpty) { ad = ad.filter(x => x.answerType !== 'none') }
        if (hideVideo) { ad = ad.filter(x => x.answerType !== 'video') }

        return ad.map((a, index) => {
            return (<div style={{ border: '1px solid black', borderRadius: 3, }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'black', padding: '0px 8px', color: 'white', }}>
                    <p style={{ margin: 0, textTransform: 'uppercase', }}><b>{`${index + 1}`}</b> {`(${a.createdAt})`}</p>
                    <p style={{ margin: 0, textTransform: 'uppercase', }}>{a.answerType}</p>
                </div>

                <div style={{ padding: '2px 8px', backgroundColor: a.answerType === 'none' ? 'grey' : 'white', }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p style={{ margin: 0, }}><b>User: </b>{a.user}</p>
                    </div>

                    <div>
                        {a.data && !a.data.includes('.webm') && a.data.split('<-:->').map(x => {
                            return (
                                <p style={{ margin: 0 }}>{x}</p>
                            )
                        })}

                        {a.data && a.data.includes('.webm') && a.data.split(';').map(x => {
                            return (
                                <div style={{ display: 'flex', gap: 8 }}>
                                    <p style={{ margin: 0 }}>{x}</p>

                                    {/* Download Button for Videos */}
                                    <svg
                                        onClick={() => {
                                            axios.get(`${Config.API_BASE_URL}/question/download-video/`, { params: { fileName: x }, responseType: 'blob' })
                                                .then(res => {
                                                    const videoBlob = new Blob([res.data], { type: 'video/mp4' }); // Assuming video type is mp4. Adjust if different.
                                                    const url = window.URL.createObjectURL(videoBlob);
                                                    const link = document.createElement("a");
                                                    link.href = url;
                                                    link.setAttribute("download", `${x}`);
                                                    document.body.appendChild(link);
                                                    link.click();
                                                })
                                                .catch(err => {
                                                    console.error("There was an error downloading the video:", err);
                                                });
                                        }}
                                        xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5" /></svg>

                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>)
        })
    }

    return (
        isAdminUser ?
            // User is an admin
            <Container>
                < h1 >Answer Data</h1 >
                <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>

                    <TextInput label="Question ID" placeholder="abcd-123" value={questionId} onChange={setQuestionId} />
                    <button className={SiteStyles.ActionButtonSlim} style={{ margin: 0 }} onClick={() => { fetchQuestion() }}>Submit</button>
                    {answers && answers.length > 0 && <button className={SiteStyles.ActionButtonSlim} style={{ margin: 0 }} onClick={() => { fetchAnalysis() }}>Analyze</button>}
                    {answers && answers.length > 0 &&
                        <div>
                            <p style={{ margin: 0, fontWeight: 'bold', }}>Total: {answers.length}</p>

                            <DualSegmentedControlInput label="Hide Hidden Answers" choiceA={"True"} choiceB={"False"} value={hideEmpty} onChange={setHideEmpty} />
                            <DualSegmentedControlInput label="Hide Video Answers" choiceA={"True"} choiceB={"False"} value={hideVideo} onChange={setHideVideo} />
                        </div>
                    }

                </div>
                <hr />

                <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
                    {answers.length > 0 && displayAnswers(answers)}
                </div>

            </Container >
            :
            // User is not an admin
            <p>Not Authorized</p>
    );
}

const mapStateToProps = state => { return {} };

const mapDispatchToProps = (dispatch, ownProps) => { return {} }

export default connect(mapStateToProps, mapDispatchToProps)(AnswerData);