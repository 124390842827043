import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import NavBar from '../../NavBar/NavBar';
import SiteStyles from '../../Site.module.css'
import TextStyles from '../../styles/text.module.css'
import DivStyles from '../../styles/divs.module.css'
import ButtonStyles from '../../styles/buttons.module.css'
import { Container } from 'react-bootstrap';
import Footer from '../../Footer/Footer';
import Styles from './MPConsultant.module.css'
import axios from 'axios';
import getCountryEmoji from '../getCountryEmoji'
import Config from '../../../config.json'

import { ArrowRightIcon, ChatIcon, ClockIcon, CorporateIcon, MoneyIcon, PersonIcon, SearchIcon } from '../../Icons'
import MPPanel from '../MPPanel/MPPanel';

const MPConsultantDetailedView = (props) => {
    let navigate = useNavigate();

    const { cid } = useParams(); // 'cid' will be the consultant's ID from the URL
    const [consultant, setConsultant] = useState(null);
    const [voucher, setVoucher] = useState(null);

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const checkLoggedIn = () => {
        axios.get(`${Config.API_BASE_URL}/auth/check-headers`).then(res => {
            if (res.data.hasAuthHeaders) {
                setIsLoggedIn(true);
            }
        }).catch(err => { console.log(err); })
    }

    const fetchConsultant = () => {
        axios.get(`${Config.API_BASE_URL}/public/consultant/${cid}`).then(res => {
            setConsultant(res.data.data);
            console.log(res.data);
            if (res.data.voucher) { setVoucher(res.data.voucher); }
        }).catch(err => { console.log('Error has occurred when fetching consultant') })
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 850);
    const [isTablet, setIsTablet] = useState(window.innerWidth <= 1000);

    useEffect(() => {
        fetchConsultant();
        checkLoggedIn();

        // Check mobile size for conditional render 
        const handleResize = () => { setIsMobile(window.innerWidth <= 850); };
        window.addEventListener('resize', handleResize);
        return () => { window.removeEventListener('resize', handleResize); };
    }, []);

    const messageConsultant = () => {
        axios.post(`${Config.API_BASE_URL}/messages/create/${cid}`).then(res => {
            navigate('/app/messages/', { state: { cid: cid } });
        }).catch(err => { console.log('Error has occurred when fetching consultant') })
    }

    const choosePackage = (p) => {
        const packageId = p._id;
        const consultantId = cid;

        if (isLoggedIn) {
            console.log('Stripe API Call')
            axios.post(`${Config.API_BASE_URL}/paymentv2/create-checkout-session-package/`, { consultantId, packageId }).then(res => {
                console.log("i did it")
                window.location.href = res.data.url;
            }).catch(err => { console.log('Error has occurred when fetching consultant') })
        } else {
            navigate('/register')
        }

    }

    return (
        <div>
            <p className={TextStyles.Bold} style={{ fontSize: 'large', }}>MARKETPLACE | <span style={{ fontWeight: 500 }}>CONSULTANT PROFILE</span></p>
            {
                consultant === null ?
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', }}>
                        <div className={SiteStyles.Spinner} />
                    </div>
                    :
                    <div style={{ padding: '16px 0px' }}>

                        <div className={Styles.TopRow}>
                            {/* First, profile picture */}
                            <img src={`${Config.IMAGES_BASE_URL}/profilepics/${consultant.profilePicture}`} style={{
                                width: 200, height: 200, borderRadius: 5
                            }} />

                            {/* Information */}
                            <div style={{ width: '100%', }}>
                                <div style={{ display: 'flex', gap: 8 }}>
                                    <span className={TextStyles.BoldPillDark}
                                        style={{ fontWeight: 600, fontSize: 'large', margin: 0, height: 'min-height', backgroundColor: '#333', borderRadius: 3 }}
                                    >{consultant.consultantCountry} {getCountryEmoji(consultant.consultantCountry)}</span>

                                    <span className={TextStyles.Body} style={{
                                        fontSize: '10.5pt', backgroundColor: 'rgba(0,0,0,0.1)', padding: '4px 16px',
                                        borderRadius: 3,
                                    }} >
                                        <div style={{ marginRight: 6, display: 'inline-block', }}>
                                            <ClockIcon width={16} height={16} />
                                        </div>
                                        Last active: {consultant.lastActive}
                                    </span>
                                </div>

                                <p className={TextStyles.Bold} style={{ fontSize: 'xxx-large', marginBottom: -8, marginTop: -8 }}>{consultant.name}</p>
                                <p className={TextStyles.Bold} style={{ fontWeight: 600, fontSize: 'large' }}>{consultant.taglineOne}</p>
                                <p className={TextStyles.Body} style={{ fontSize: 'large', marginBottom: 8 }} >{consultant.taglineTwo}</p>

                                <button className={ButtonStyles.StandardDark}
                                    onClick={() => { messageConsultant() }}>
                                    Message
                                    <div style={{ display: 'inline-block', marginLeft: 8 }}>
                                        <ChatIcon color="white" />
                                    </div>
                                </button>

                            </div>
                        </div>

                        {/* About Me */}
                        <div className={DivStyles.StandardLight} style={{ marginBottom: 16, padding: 24 }}>
                            <p className={TextStyles.Bold} style={{ fontWeight: 600, fontSize: 'large', textAlign: 'center', marginBottom: 8 }}>ABOUT ME</p>
                            {consultant.aboutMe.split('\n').map(x => {
                                return (<p className={TextStyles.Body} style={{ fontSize: 'large', marginBottom: 8 }} >{x}</p>)
                            })}
                        </div>

                        <div style={{ display: 'flex', flexWrap: isMobile ? 'wrap' : null, width: '100%', gap: 16, marginBottom: 16 }}>
                            {/* Packages */}
                            <div className={DivStyles.StandardLight} style={{ width: isMobile ? '100%' : '50%', padding: 24 }}>
                                <p className={TextStyles.Bold} style={{ fontWeight: 600, fontSize: 'large', textAlign: 'center', marginBottom: 16 }}>PACKAGES</p>
                                {
                                    voucher &&
                                    <div style={{
                                        border: '1px solid green', padding: '2px 8px', borderRadius: 3, marginBottom: 24,
                                        boxShadow: '0px 6px 12px rgba(0,100,0,0.2)', background: 'linear-gradient(180deg, white, #e3ffef)'
                                    }}>
                                        <p className={TextStyles.Bold} style={{ fontSize: '8pt', color: '#044720' }}>MARKETPLACE VOUCHER</p>
                                        <p className={TextStyles.Bold} style={{ color: '#044720' }}>{voucher.title}</p>
                                        <p className={TextStyles.Body} style={{ color: '#044720', fontSize: 'small' }}>This voucher will be automatically applied during checkout for any package with a minimum value of ${voucher.minOrderValue / 100}. Simply choose an eligible package below.</p>
                                    </div>
                                }
                                {consultant.packages.map(x => {
                                    return (
                                        <div className={Styles.Package} style={{ fontSize: 'large', marginBottom: 8 }}
                                            onClick={() => { choosePackage(x) }}>
                                            <div>
                                                <div style={{ display: 'flex', gap: 8, }}>
                                                    <p className={TextStyles.Bold} style={{
                                                        fontSize: '11pt', textTransform: 'uppercase', fontWeight: 700, marginBottom: 2,
                                                        backgroundColor: 'rgba(0,0,0,0.1)', padding: '0px 8px', borderRadius: '3px',
                                                    }} >${x.price}</p>
                                                    <p className={TextStyles.Body} style={{
                                                        fontSize: '11pt', textTransform: 'uppercase', fontWeight: 600, marginBottom: 2,
                                                        backgroundColor: 'rgba(0,0,0,0.1)', padding: '0px 8px', borderRadius: '3px',
                                                    }} >{x.time} minutes</p>
                                                    {x.live && <p className={TextStyles.Body} style={{
                                                        fontSize: '11pt', textTransform: 'uppercase', fontWeight: 600, marginBottom: 2,
                                                        backgroundColor: 'rgba(0,0,0,0.1)', padding: '0px 8px', borderRadius: '3px',
                                                    }} >Live</p>}
                                                </div>
                                                <p className={TextStyles.Bold} style={{ fontSize: 'large', marginBottom: 2 }} >{x.title}</p>
                                                <p className={TextStyles.Body} style={{ fontSize: 'large', marginBottom: 2 }} >{x.description}</p>
                                            </div>
                                            <div style={{ minWidth: 20 }}>
                                                <ArrowRightIcon />
                                            </div>
                                        </div>
                                    )
                                })}

                                <p style={{ marginTop: 16 }} className={TextStyles.Body}>Make sure you communicate with {consultant.name} before purchasing any packages.</p>
                                <button className={ButtonStyles.ModernButton} style={{ paddingLeft: 0, marginLeft: 0 }} onClick={() => { messageConsultant() }}>
                                    Send {consultant.name} a Message →</button>
                            </div>

                            <div className={DivStyles.StandardLight} style={{ width: isMobile ? '100%' : '50%', padding: 24 }}>

                                {/* Education — Only display if they've been entered */}
                                {consultant.education && consultant.education.length > 0 &&
                                    <>
                                        <p className={TextStyles.Bold} style={{ fontWeight: 600, fontSize: 'large', textAlign: 'center', marginBottom: 16 }}>EDUCATION & EXPERIENCE</p>

                                        <div style={{ display: 'flex', gap: 16 }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {consultant.education.map(x => {
                                                    return (
                                                        <div style={{ display: 'flex' }} id={x.title}>
                                                            <div style={{ width: 110, maxWidth: 110, minWidth: 110 }}>
                                                                <p className={TextStyles.Bold} style={{ fontSize: 'large', marginBottom: 8 }}>{x.startYear} - {x.endYear}</p>
                                                            </div>
                                                            <p className={TextStyles.Body} style={{ fontSize: 'large', marginBottom: 8 }} >{x.title}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <br />
                                    </>
                                }


                                {/* Test Scores — Only display if they've been entered */}
                                {consultant.testScores && consultant.testScores.length > 0 &&
                                    <>
                                        <p className={TextStyles.Bold} style={{ fontWeight: 600, fontSize: 'large', textAlign: 'center', marginBottom: 16 }}>TEST SCORES</p>
                                        <div style={{ display: 'flex', gap: 16 }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {consultant.testScores.map(x => {
                                                    return (<p className={TextStyles.Bold} style={{ fontSize: 'large', marginBottom: 8 }} >{x.title}</p>)
                                                })}
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {consultant.testScores.map(x => {
                                                    return (<p className={TextStyles.Body} style={{ fontSize: 'large', marginBottom: 8 }} >{x.score}</p>)
                                                })}
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>

                            {/* Footer */}
                        </div>
                    </div>
            }
        </div >
    );
}

const mapStateToProps = state => {
    return {
        URLRef: state.URLRef,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setIsAuthenticated: (val) => dispatch({ type: "SET_AUTHENTICATION", payload: { value: val } }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MPConsultantDetailedView);