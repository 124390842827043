import React, { useState, useEffect } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SuccessItem from '../App/subcomponents/SuccessItem';
import axios from 'axios';
import Config from '../../config.json'
import processPolicy from '../App/helpers/processPolicy';

import SiteStyles from '../App/WebApp.module.css'
import Styles from '../Site.module.css'
import TextStyles from '../TextStyles.module.css'
import ErrorItem from '../App/subcomponents/ErrorItem';


const Privacy = (props) => {
    let navigate = useNavigate();
    const [processedPolicy, setProcessedPolicy] = useState("");
    const [lastUpdated, setLastUpdated] = useState("Loading...");
    const [loadedPolicy, setLoadedPolicy] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
        });

        axios.get(`${Config.API_BASE_URL}/auth/privacypolicy`).then(res => {
            if (res.data.success) {
                setProcessedPolicy(processPolicy(res.data.data.policy));
                setLastUpdated(res.data.data.lastUpdated);
                setLoadedPolicy(true);
            } else {
                console.error("Error fetching privacy policy:", res.data.message);
            }
        }).catch(err => {
            console.error("Error fetching privacy policy:", err);
        });
    }, [])


    return (
        <div style={{ backgroundColor: '#1e3799' }}>
            <Container>
                <br />
                <button className={Styles.ActionButton} style={{
                    backgroundColor: 'white', color: '#1e3799'
                }} onClick={() => { navigate(-1) }}>Back</button>
                <br />
                <br />
                <p className={TextStyles.TitleWhite} style={{ fontSize: '32pt' }}>Privacy Policy</p>
                <div className={Styles.DynamicPane} style={{ padding: '32px' }}>
                    <p className={TextStyles.Body} style={{ textAlign: 'right', fontWeight: 'bold' }}>Last Revised: {lastUpdated}</p>

                    {loadedPolicy ? <div dangerouslySetInnerHTML={{ __html: processedPolicy }}></div> :
                        <div style={{ width: '100%', display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                            <div className={SiteStyles.Spinner} />
                        </div>
                    }
                </div>
                <br />
                <br />
                <br />

            </Container >
        </div >
    );
}

export default (Privacy);