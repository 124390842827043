import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import TextStyles from '../../styles/text.module.css';
import Config from '../../../config.json'
import Styles from './MPHome.module.css';
import MPPanel from '../MPPanel/MPPanel';
import SiteStyles from '../../Site.module.css';


const MPMarketplace = ({ dontNavigate = false }) => {
    const [loadingConsultants, setLoadingConsultants] = useState(false);
    const [consultants, setConsultants] = useState([]);
    const [voucherMinOrderValue, setVoucherMinOrderValue] = useState(null);
    const [voucherValue, setVoucherValue] = useState(null);
    const [allTags, setAllTags] = useState([]);

    const fetchConsultants = () => {
        setLoadingConsultants(true);
        axios.get(`${Config.API_BASE_URL}/public/consultants`)
            .then(res => {
                setConsultants(res.data.data)
                setLoadingConsultants(false);
                if (res.data.voucherValue) {
                    setVoucherMinOrderValue(res.data.minOrderValue);
                    setVoucherValue(res.data.voucherValue);
                }
            })
            .catch(err => { console.log("Error fetching user count") })
    }

    const fetchAllTags = () => {
        axios.get(`${Config.API_BASE_URL}/public/tags`, {})
            .then(res => { setAllTags(res.data.tags); })
            .catch(error => { console.error(error); });
    }

    useEffect(() => { fetchConsultants(); fetchAllTags(); }, [])

    return (
        loadingConsultants ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding: '64px' }}>
                <div className={SiteStyles.Spinner} />
            </div>
            :
            <div id="marketplace-grid" style={{ backgroundColor: 'rgb(235,235,245)', padding: 24, zIndex: 100 }}>
                <p className={TextStyles.Body} style={{ color: 'black', fontWeight: '600', textAlign: 'center', fontSize: '14pt', }}>OUR CONSULTANTS</p>
                <Container>
                    <div className={Styles.MarketplaceGrid}>
                        {consultants.map(x => <MPPanel consultant={x} dontNavigate={dontNavigate} voucherMinOrderValue={voucherMinOrderValue} voucherValue={voucherValue} />)}
                    </div>
                </Container>
            </div>
    );
};

const mapStateToProps = (state) => { return { isAuthenticated: state.isAuthenticated, }; };

export default connect(mapStateToProps)(MPMarketplace);