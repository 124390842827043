import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Config from '../../../../config.json';

import Styles from './RatingView.module.css';
import TextStyles from '../../../TextStyles.module.css';
import SiteStyles from '../../../Site.module.css';
import ButtonStyles from '../../../styles/buttons.module.css';
import { useNavigate } from 'react-router-dom';
import formatUnixTime from '../../helpers/formatUnixTime';

import StarRating from './StarRating';

const RatingView = ({ threadId, fetchOwn, display, multipleRatings, updateRatingCount, subId, label = "Rate", staticAvgRating = 0, staticNumRatings = 0 }) => {
    let navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState(null);  // Add this state variable for error message

    // Every Fetch will retrieve these values 
    const [rating, setRating] = useState(0);
    const [avgRating, setAvgRating] = useState(null);
    const [numRatings, setNumRatings] = useState(null);
    const [ratingText, setRatingText] = useState("");

    // Only if detailed ratings are fetched
    const [ratings, setRatings] = useState([]);

    // Admin
    const [displayName, setDisplayName] = useState("");
    const [currentlyEditing, setCurrentlyEditing] = useState(null);

    const updateRating = async (r) => {
        if (r < 1 || r > 5) return;
        setRating(r);
        submitRating(r);
    }

    const fetchRatings = () => {
        setRating(0);
        setAvgRating(null);
        setNumRatings(null);

        axios.get(`${Config.API_BASE_URL}/rating/${threadId}`, {
            params: { detailed: display === 'full', fetchOwn, multipleRatings, subId }
        }).then(res => {
            if (display === 'full') { setRatings(res.data.finalRatings); }

            setAvgRating(res.data.avgRating);
            setNumRatings(res.data.numRatings);


            // Check if the user has rated this question
            if (res.data.userRating) { setRating(res.data.userRating); }
            if (updateRatingCount) { updateRatingCount(res.data.numRatings ?? 0); }
        }).catch(err => { console.log(err); })
    }

    useEffect(() => {
        if (display !== 'static') { fetchRatings(); }
    }, [threadId, subId]);

    const refresh = () => {
        setRatingText("");
        setDisplayName("");
        setCurrentlyEditing(null);
        setErrorMessage(null);
        fetchRatings();
    }

    const submitRating = async (r) => {
        if (!r) return;

        try {
            const response = await axios.post(`${Config.API_BASE_URL}/rating/${threadId}`, { rating: r, text: null, subId: subId });

            if (response.data.error) { setErrorMessage(response.data.error); }
            else { refresh(); }
        } catch (error) {
            console.log(error);
            if (error && error.response && error.response.data && error.response.data.message) { setErrorMessage(error.response.data.message); }
            else { setErrorMessage("Unknown error has occurred"); }
        }
    }

    const deleteRating = async (ratingId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this comment?');
        if (!confirmDelete) { return; }

        try {
            const response = await axios.put(`${Config.API_BASE_URL}/rating/${threadId}/${ratingId}/delete`);

            if (response.data.error) { setErrorMessage(response.data.error); }
            else { refresh(); }
        } catch (error) {
            console.log(error);
            if (error && error.response && error.response.data && error.response.data.message) { setErrorMessage(error.response.data.message); }
            else { setErrorMessage("Unknown error has occurred"); }
        }
    }

    const changeDate = async (ratingId, numDays) => {
        try {
            const response = await axios.put(`${Config.API_BASE_URL}/rating/${ratingId}/changedate`, { numDays });
            if (response.data.error) { setErrorMessage(response.data.error); }
            else { refresh(); }
        } catch (error) {
            console.log(error);
            if (error && error.response && error.response.data && error.response.data.message) { setErrorMessage(error.response.data.message); }
            else { setErrorMessage("Unknown error has occurred"); }
        }
    }

    const updateDisplayName = async (ratingId) => {
        try {
            const response = await axios.put(`${Config.API_BASE_URL}/rating/${ratingId}/displayName`, { displayName });
            if (response.data.error) { setErrorMessage(response.data.error); }
            else { refresh(); }
        } catch (error) {
            console.log(error);
            if (error && error.response && error.response.data && error.response.data.message) { setErrorMessage(error.response.data.message); }
            else { setErrorMessage("Unknown error has occurred"); }
        }
    }

    switch (display) {
        case "average-only-hide":
            return (
                numRatings && numRatings !== 0 ?
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {/* STAR RATING */}
                        <div className={Styles.AverageOnlyDiv}>
                            <div style={{ minWidth: '160px', maxWidth: '160px', width: '160px', pointerEvents: 'none' }}>
                                <StarRating key={`avg-${subId}`} rating={avgRating} onRatingChange={updateRating} />
                            </div>
                            <p className={Styles.LabelText}>{`(${(numRatings ?? "...")})`}</p>
                        </div>
                    </div > : null
            );
        case "static":
            return (
                staticNumRatings && staticNumRatings !== 0 ?
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {/* STAR RATING */}
                        <div className={Styles.AverageOnlyDiv} >
                            <div style={{ minWidth: '160px', maxWidth: '160px', width: '160px', pointerEvents: 'none' }}>
                                <StarRating key={`avg-${subId}`} rating={staticAvgRating} onRatingChange={updateRating} />
                            </div>
                            <p className={Styles.LabelText}>{`(${staticNumRatings})`}</p>
                        </div>
                    </div > : null
            );
        case "average-only":
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {/* STAR RATING */}
                    <div className={Styles.AverageOnlyDiv}>
                        <div style={{ minWidth: '160px', maxWidth: '160px', width: '160px', pointerEvents: 'none' }}>
                            <StarRating key={`avg-${subId}`} rating={avgRating} onRatingChange={updateRating} />
                        </div>
                        <p className={Styles.LabelText}>{`(${numRatings ?? "..."})`}</p>
                    </div>
                </div >
            );
        case "rate-only":
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {/* STAR RATING */}
                    <div className={Styles.RateOnlyDiv}>
                        <p className={Styles.LabelTextSmall}>{label}</p>
                        <div style={{ minWidth: '160px', maxWidth: '160px', width: '160px' }}>
                            <StarRating key={`rate-${subId}`} rating={rating} onRatingChange={updateRating} />
                        </div>
                    </div>
                </div >
            );
        case "average-rate":
            return (
                <p>average-rate</p>
            );
        case "full":
            return (
                <p>full</p>
            );
        default:
            return (<p>rating display error</p>);
    }
}

export default RatingView;
