import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import SiteStyles from '../../Site.module.css';
import Styles from './MPPanel.module.css';
import TextStyles from '../../styles/text.module.css';
import ButtonStyles from '../../styles/buttons.module.css';
import Config from '../../../config.json';
import { ArrowRightIcon, ChatIcon } from '../../Icons';
import getCountryEmoji from '../getCountryEmoji';
import RatingView from '../../App/subcomponents/RatingView/RatingView';

const MPPanel = ({ consultant, dontNavigate = false, voucherMinOrderValue = null, voucherValue = null }) => {
    let navigate = useNavigate();

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const visitProfile = () => {
        navigate(`/consultant/${consultant._id}`);
    };

    const checkLoggedIn = () => {
        axios.get(`${Config.API_BASE_URL}/auth/check-headers`).then(res => {
            if (res.data.hasAuthHeaders) {
                setIsLoggedIn(true);
            }
        }).catch(err => {
            console.log(err);
        });
    };

    useEffect(() => {
        checkLoggedIn();

        // Check mobile size for conditional render 
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const messageConsultant = () => {
        if (isLoggedIn) {
            axios.post(`${Config.API_BASE_URL}/messages/create/${consultant._id}`).then(res => {
                if (!dontNavigate) {
                    navigate('/app/messages/', { state: { conversationId: res.data._id } });
                }
            }).catch(err => {
                console.log('Error has occurred when fetching consultant');
            });
        } else {
            navigate('/register');
        }
    };

    // Determine if voucher applies
    const voucherApplies = voucherValue !== null && (voucherMinOrderValue / 100) <= parseInt(consultant.hourlyRate);
    const discountedRate = voucherApplies ? consultant.hourlyRate - (voucherValue / 100) : consultant.hourlyRate;

    return (
        <div className={Styles.ConsultantPanel}>
            <div className={Styles.TopRow} onClick={visitProfile}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 4, maxWidth: 80 }}>
                    <img src={`${Config.IMAGES_BASE_URL}/profilepics/${consultant.profilePicture}`}
                        style={{ width: 80, height: 80, borderRadius: 3 }} />
                    {consultant.consultantComputedBadges.includes('popular') &&
                        <p style={{
                            fontSize: 'small', textAlign: 'center',
                            borderRadius: 3, lineHeight: '13pt'
                        }} className={TextStyles.BoldPillBlueLight}>Popular</p>
                    }
                    {consultant.consultantComputedBadges.includes('new') &&
                        <p style={{
                            fontSize: 'small', textAlign: 'center',
                            borderRadius: 3, lineHeight: '13pt'
                        }} className={TextStyles.BoldPillPurpleLight}>New</p>
                    }
                    {consultant.consultantComputedBadges.includes('recentactivity') &&
                        <p style={{
                            fontSize: 'small', textAlign: 'center',
                            borderRadius: 3, lineHeight: '13pt', fontWeight: 600
                        }} className={TextStyles.BoldPillLight}>Recently Online</p>
                    }
                </div>
                <div>
                    <div style={{
                        display: 'flex', width: '100%', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row',
                    }}>
                        {!isMobile && getCountryEmoji(consultant.consultantCountry)}
                        <div style={{ transform: 'scale(0.9)', transformOrigin: isMobile ? 'center' : 'right' }}>
                            <RatingView
                                multipleRatings={true}
                                staticAvgRating={consultant.consultantReviewAvg}
                                staticNumRatings={consultant.consultantReviewCount}
                                threadId={`consultant-${consultant._id}`}
                                fetchOwn={false}
                                display={"static"}
                            />
                        </div>
                    </div>
                    <p className={TextStyles.Bold} style={{ fontSize: 'x-large' }}>
                        {consultant.name} {isMobile && getCountryEmoji(consultant.consultantCountry)}
                    </p>
                    <p className={TextStyles.Bold} style={{ fontWeight: 600 }}>{consultant.taglineOne}</p>
                    <p className={TextStyles.Body}>{consultant.taglineTwo}</p>
                </div>
            </div>

            <div className={Styles.BottomBar}>
                <p className={TextStyles.BoldWhite} style={{ fontSize: '14pt', marginLeft: 8, zIndex: 10000 }}>
                    {voucherApplies ? (
                        <>
                            <span style={{ textDecoration: 'line-through', opacity: 0.8, fontSize: '17pt' }}>
                                ${consultant.hourlyRate}
                            </span>
                            <span style={{ color: 'white', marginLeft: 8, fontSize: '17pt' }}>
                                ${discountedRate}/hour
                            </span>
                        </>
                    ) : (
                        <>
                            <span style={{ fontSize: '17pt' }}>${consultant.hourlyRate}</span>/hour
                        </>
                    )}
                </p>

                <div style={{ display: 'flex', gap: 8, zIndex: 10000 }}>
                    <button className={Styles.BarButton} onClick={messageConsultant}>
                        Message
                        <div style={{ display: 'inline-block', marginLeft: 8 }}>
                            <ChatIcon color="white" />
                        </div>
                    </button>

                    <button className={Styles.BarButton} onClick={visitProfile}>
                        Packages
                        <div style={{ display: 'inline-block', marginLeft: 8 }}>
                            <ArrowRightIcon color="white" />
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};

// Function to map state from store to props
const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.isAuthenticated,
    };
};

// Use the connect higher-order component to connect MPPanel to the Redux store
export default connect(mapStateToProps)(MPPanel);
