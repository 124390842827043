import Config from '../../config.json'

const getCountryEmoji = (c) => {
    switch (c) {
        case "USA":
            return <img src={`${Config.IMAGES_BASE_URL}/countryflags/usa.png`} alt="USA" style={{ width: '22px', height: '22px' }} />;
        case "CAN":
            return <img src={`${Config.IMAGES_BASE_URL}/countryflags/can.png`} alt="USA" style={{ width: '22px', height: '22px' }} />;
        case "GBR":
            return "🇬🇧"; // United Kingdom
        case "AUS":
            return "🇦🇺"; // Australia
        case "NZL":
            return "🇳🇿"; // New Zealand
        case "SGP":
            return "🇸🇬"; // Singapore
        case "FRA":
            return "🇫🇷"; // France
        case "CHN":
            return "🇨🇳"; // China
        case "DEU":
            return "🇩🇪"; // Germany
        default:
            return c; // Return the original country code if not found
    }
};

export default getCountryEmoji;