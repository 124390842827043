const initialState = {
    test: null,
    testConfiguration: null,
    successRegistration: null,
    subscriptionInformation: null,
    URLRef: null,
    isAuthenticated: false,
}

const rootReducer = (currentState = initialState, action) => {
    switch (action.type) {
        case 'SET_TEST':
            return {
                ...currentState,
                test: action.payload.value
            }

        case 'SET_TEST_CONFIGURATION':
            return {
                ...currentState,
                testConfiguration: action.payload.value
            }

        case 'SET_SUCCESS_REGISTRATION':
            return {
                ...currentState,
                successRegistration: action.payload.value
            }

        case 'SET_SUBSCRIPTION_INFO':
            return {
                ...currentState,
                subscriptionInformation: {
                    subscriptionLevel: action.payload.value.subscriptionLevel,
                    subscriptionExpiry: action.payload.value.subscriptionExpiry
                }
            }

        case 'SET_AUTHENTICATION':
            return {
                ...currentState,
                isAuthenticated: action.payload.value,
            }

        case 'SET_URLREF':
            return {
                ...currentState,
                URLRef: action.payload,
            }

        default:
            return currentState;
    }
}

export default rootReducer;
